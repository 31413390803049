import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js';
import 'chartjs-plugin-labels'

// Connects to data-controller="chart"
export default class extends Controller {
  static values = {
    color: String,
    scores: Array,
    type: String,
  }
  static targets = ["canvas", "title", "paragraph", "link"]

  connect() {
    if (this.typeValue === 'donut') {
      this.renderDonut();
    } else if (this.typeValue === 'pie') {
      this.renderPie();
    }
  }

  renderDonut() {
    if(!this.hasCanvasTarget) return;

    const ctx = this.canvasTarget.getContext('2d');
    const scores = this.scoresValue
    const color = this.colorValue

    this.graph = new Chart(ctx, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: scores,
            backgroundColor: [color, "#d0d0d0"],
            borderWidth: 0,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
        },
        events: [],
        plugins: {
          labels: {
            render: () => "",
          },
        },
      }
    });
  }

  renderPie() {
    if(!this.hasCanvasTarget) return;

    const ctx = this.canvasTarget.getContext('2d');
    const color = this.colorValue
    const backgroundColor = ['#e0e0e0', color, '#e0e0e0']
    const pillarKeys = this.scoresValue.map(object => JSON.parse(object)['key'])
    const labels = this.scoresValue.map(object => JSON.parse(object)['score'])
    const title = this.titleTarget
    const paragraph = this.paragraphTarget
    const scores = this.scoresValue
    const link = this.linkTarget

    let graph = new Chart(ctx, {
      type: 'pie',
      data: {
        datasets: [
          {
            data: [1/3, 1/3, 1/3],
            borderWidth: 5,
            backgroundColor: backgroundColor,
            hoverBorderColor: 'white',
            hoverBackgroundColor: color
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        onClick: function (e) {
          const element = graph.getElementAtEvent(e)[0]

          if (element) {
            element._chart.canvas.parentElement.nextElementSibling.lastElementChild.scrollTop = 0
            element._chart.config.data.datasets[0].backgroundColor = pillarKeys
              .map(key => pillarKeys[element._index] === key ? color : '#e0e0e0')

            const element_content = JSON.parse(scores[element._index])
            title.innerText = element_content['title']
            paragraph.innerText = element_content['paragraph']
            link.href = element_content['agile_tip_path']

            this.update()
          }
        },
        plugins: {
          labels: {
            render: ({index}) => labels[index],
            fontColor: '#fff',
            fontSize: 26,
            fontStyle: 'bold'
          }
        }
      }
    });
  }
}
