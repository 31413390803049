import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multiselect"
export default class extends Controller {
  static targets = [ "checkbox" ]
  static values = { closeIcon: String, attribute: String }

  connect() {
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) this.toggleSelectedOptionLabel({ target: checkbox })
    })
  }

  toggleSelectedOptionLabel(event) {
    const checkbox = event.target
    const selectedOptionId = checkbox.getAttribute("data-text")?.toLowerCase().replace(/\s+/g, "_").replace(/[^a-z0-9_]/g, "");
    if (!selectedOptionId) return

    if (checkbox.checked) {
      this.addSelectedOptionLabel(checkbox, selectedOptionId)
    } else {
      const optionLabel = this.selectedOptionsElement().querySelector(`#${selectedOptionId}`)
      optionLabel.remove()
    }
  }

  addSelectedOptionLabel(checkbox, optionId) {
    const optionLabel = document.createElement("label")
    optionLabel.id = optionId
    optionLabel.classList.add("flex", "items-center", "border", "border-blue-dark", "rounded", "p-1", "mr-2", "mb-2")

    const spanElement = document.createElement("span")
    spanElement.innerText = checkbox.getAttribute("data-text")
    spanElement.classList.add("truncate", "text-xs")

    optionLabel.appendChild(spanElement)
    optionLabel.appendChild(this.removeIcon(checkbox, optionLabel))
    this.selectedOptionsElement().appendChild(optionLabel)
  }

  removeIcon(checkbox, optionLabel) {
    const removeIcon = document.createElement("img")
    removeIcon.src = this.closeIconValue
    removeIcon.classList.add("h-3.5", "w-3.5", "ml-1", "cursor-pointer", "flex-shrink-0")

    this.addListenerToIcon(removeIcon, checkbox, optionLabel)
    return removeIcon
  }

  addListenerToIcon(removeIcon, checkbox, optionLabel) {
    removeIcon.addEventListener("click", () => {
      checkbox.checked = false

      // Trigger action events when unchecking the checkbox
      const event = new Event("input");
      checkbox.dispatchEvent(event);

      // Trigger change event on the form to allow the form to submit on change
      const form = checkbox.form
      if (form) {
        form.dispatchEvent(new Event("change"))
      }

      optionLabel.remove()
    })
  }

  selectedOptionsElement() {
    const selectedOptionsElementId = `${this.attributeValue}_selected_options`
    return document.getElementById(selectedOptionsElementId)
  }
}
